<template>
  <div class="wrap-select-input" v-click-outside="onClickOutside">
    <div class="title">{{ title }}</div>
    <div class="select" :class="{ active: optionShow }">
      <div class="select-selected" @click="optionShow = !optionShow">
        <input
          :lang="userFlatObj.language"
          type="text"
          v-model="inputValue"
          @click.stop="handleClick"
          @keyup.enter="optionShow = false"
          :placeholder="placeholder"
        />
        <img
          :class="{ 'arrow-up': optionShow }"
          src="@/assets/icons/Drop.svg"
          alt=""
        />
      </div>
      <div v-if="optionShow && options.length > 0" class="select-options">
        <div
          v-for="item in options"
          :key="item.label"
          class="select-option"
          :class="{ selected: value === item.value }"
          @click="optionSelect(item.value)"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import vClickOutside from 'v-click-outside'

export default {
  name: 'SelectInput',
  props: ['title', 'value', 'options', 'placeholder'],
  data() {
    return {
      optionShow: false
    }
  },
  computed: {
    ...mapState(['userFlatObj']),
    inputValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
        this.optionShow = false
      }
    },
    selectLabel() {
      let str = ''
      if (Array.isArray(this.value)) {
        let option = this.options.find((item) => {
          if (item.value.length === this.value.length) {
            let isEqual = true
            item.value.forEach((p) => {
              if (!this.value.includes(p)) isEqual = false
            })
            return isEqual
          } else return false
        })
        return option?.label
      } else {
        str = this.options.find((item) => item.value === this.value)?.label
      }

      return str
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    getIcon(fn) {
      return require(`@/assets/icons/${fn}`)
    },
    handleClick() {
      this.optionShow = !this.optionShow
    },
    optionSelect(value) {
      let tmpVal = value
      this.optionShow = false
      this.$emit('input', tmpVal)
    },
    onClickOutside() {
      this.optionShow = false
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  user-select: none;
}

.wrap-select-input {
  width: 100%;
}
.title {
  color: #ffffff;
  font-size: px2rem(20);
  font-weight: 300;
  line-height: px2rem(28);
  margin-bottom: px2rem(4);
}

.select {
  width: 100%;
  position: relative;
}

.select.active .select-selected::after {
  transform: rotate(180deg);
}

.select-selected {
  display: flex;
  align-items: center;
  width: 100%;
  height: px2rem(48);
  background: #ffffff;
  color: #191919;
  border-radius: px2rem(8);
  padding: 0 px2rem(12);
  font-size: px2rem(20);
  font-weight: 300;
  line-height: px2rem(48);
  position: relative;

  img {
    width: 20px;
    height: 20px;
    @include filter_191919;
    transition: all 200ms ease-in-out;

    &.arrow-up {
      transform: rotate(180deg);
    }
  }
}

input {
  outline: none;
  border: none;
  width: 100%;
  height: px2rem(40);
  font-size: px2rem(18);
  font-weight: normal;
  line-height: px2rem(32);
  color: #191919;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &::placeholder {
    color: #9d9d9d;
    font-weight: 300;
  }

  &:lang(ja)::placeholder {
    font-size: 1rem;
  }
}

.select-options {
  position: absolute;
  top: calc(100% + 4px);
  width: 100%;
  color: #ffffff;
  background: #151b35;
  padding: px2rem(6) 0;
  border: 1px solid #4a5c78;
  border-radius: 8px;
  z-index: 1;
  transition: all 250ms ease-in-out;
  min-height: px2rem(48);
  max-height: px2rem(500);
  overflow: overlay;
}

.select-option {
  user-select: none;
  padding: 4px 6px;
  font-size: px2rem(20);
  font-weight: 300;
  line-height: px2rem(32);
  word-wrap: break-word;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.select-option:not(.selected):hover {
  background: #4a5c78;
}

.selected {
  background: #6e7d93;
}

.select-option .icon {
  width: px2rem(20);
  margin-right: px2rem(16);
  display: flex;
  justify-content: center;
}

.checked {
  background: #ffc600;
  border-color: #ffc600;
}

::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  -webkit-border-radius: px2rem(2);
  border-radius: px2rem(2);
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: px2rem(2);
  border-radius: px2rem(2);
  background: #9d9d9d;
}

::-webkit-scrollbar-thumb:horizontal {
  border-radius: px2rem(6);
}

::-webkit-scrollbar-corner {
  background: #151b35;
}

@media screen and (max-width: 1680px) {
  .title {
    font-size: px2rem(20);
  }
  .select-option {
    font-size: px2rem(22);
    // padding-left: px2rem(30);
  }
  .select-option .icon {
    margin-right: px2rem(8);
  }
}

@media screen and (max-width: 1440px) {
  .title {
    font-size: px2rem(18);
  }
  .select-selected {
    font-size: px2rem(18);
  }

  input {
    font-size: px2rem(20);
  }

  .select-option {
    font-size: px2rem(18);
    // padding-left: px2rem(10);
  }
  .select-option .icon {
    margin-right: px2rem(6);
  }
}
</style>
