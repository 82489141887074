<template>
  <div class="wrap-search-bar">
    <!-- <div v-if="permissionV2.lprEventAccess > 0 && 
      permissionV2.lprEventAdvancedAccess > 0" class="tabs">
      <div 
        v-if="permissionV2.lprEventAccess > 0"
        class="tab" 
        :class="{ active: searchTab === 0 }"
        @click="selectTab(0)">
        {{ $t('search_tab_history') }}
      </div>
      <div 
        v-if="permissionV2.lprEventAdvancedAccess > 0"
        class="tab" 
        :class="{ active: searchTab === 1 }"
        @click="selectTab(1)">
        {{ $t('search_tab_advanced_search') }}
      </div>
    </div> -->
    <TopNav
      v-if="
        permissionV2.lprEventAccess > 0 &&
        permissionV2.lprEventAdvancedAccess > 0
      "
      :navList="permissionNavList"
      :isSubNav="true"
      v-model="searchTab"
      @goTo="selectTab"
    />
    <div key="history" v-if="searchTab === 0" class="search-bar">
      <div class="action-cause">
        <div class="title">{{ $t('search_cause') }}<span class="required"> *</span></div>
        <SelectInput v-model="queryCause" :options="getInputOptions(queryCauses)" :placeholder="$t('search_cause_placehold')" />
      </div>
      <div class="plate-device">
        <div class="query-type">
          <RadioSelect v-model="queryType" :options="queryTypeOptions" />
        </div>
        <div v-if="queryType === 'plate'" class="plate">
          <div class="title">{{ $t('search_plate_number_required') }}<span class="required"> *</span></div>
          <SelectInput v-model="licensePlate" :options="getInputOptions(queryNumber)" @input="licensePlate = licensePlate.toUpperCase()" :placeholder="$t('license_plate_example')" />
        </div>
        <div v-else class="query-device">
          <div class="title">{{ $t('search_device_required') }}<span class="required"> *</span></div>
          <DeviceRadioTree v-model="selectedDevice" :isGroupSelect="false" :isPublic="true" />
        </div>
      </div>
      <DateRangeSelect 
        v-if="queryType === 'plate'" 
        class="dt-select" 
        v-model="basicDateRange.value" 
        :range="true"
        :title="$t('search_time_interval')"
        :maxRangeDays="basicDateRange.maxRangeDays"
        :enabledDateStart="basicDateRange.enabledDateStart" 
        :enabledDateEnd="basicDateRange.enabledDateEnd" 
      />
      <DateRangeSelect 
        v-else 
        class="dt-select" 
        v-model="deviceDateTime" 
        :range="false" 
        :interval="30"
        :title="$t('search_time_interval')"
        :enabledDateStart="basicDateRange.enabledDateStart" 
        :enabledDateEnd="basicDateRange.enabledDateEnd" 
      />
      <div class="search-btn" :class="{loading: searchEventLoading}" @click="searchHistoryEvents()">
        <div v-if="searchEventLoading" class="ring"></div>
      </div>
      <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('search_clear')/*清除*/" placement="bottom">
        <div class="tool" @click="earseSearchParams">
          <img src="@/assets/icons/history-erase.svg" alt="">
        </div>
      </el-tooltip>
      <div 
        class="export-btn" 
        :class="{ disabled: historyEventList.length === 0 || !isReadyBasicExport }" 
        @click="basicExport">
        <img src="@/assets/icons/export-events.svg" alt="">
        {{ $t('search_export') }}
      </div>
    </div>
    <div key="advancedSearch" v-else-if="searchTab === 1" class="search-bar">
      <DeviceRadioTree v-model="selectedDevice" class="device" :title="$t('search_device')" :isGroupSelect="true" :isPublic="false" />
      <div v-if="!isNpa" class="plate">
        <div class="title">{{ $t('search_plate_number'/*車號*/) }}</div>
        <SelectInput v-model="advLicensePlate" :options="getInputOptions(queryNumber)" @input="advLicensePlate = advLicensePlate.toUpperCase()" :placeholder="$t('license_plate_example')" />
      </div>
      <!-- <Select class="car-type" :title="carType.title" v-model="advCarType" :options="carType.options" /> -->
      <DateRangeSelect 
        class="dt-select" 
        v-model="advDateRange.value" 
        :range="true" 
        :title="$t('search_time_interval')"
        :maxRangeDays="advDateRange.maxRangeDays"
        :enabledDateStart="advDateRange.enabledDateStart" 
        :enabledDateEnd="advDateRange.enabledDateEnd" 
      />
      <div class="search-btn" :class="{loading: searchEventLoading}" @click="advancedSearch()">
        <div v-if="searchEventLoading" class="ring"></div>
      </div>
      <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('search_clear')/*清除*/" placement="bottom">
        <div class="tool" @click="earseAdvSearchParams">
          <img src="@/assets/icons/history-erase.svg" alt="">
        </div>
      </el-tooltip>
      <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('search_adv_filter')/*進階條件*/" placement="bottom">
        <div class="tool" @click="showAdvSelect">
          <img src="@/assets/icons/history-filter.svg" alt="">
        </div>
      </el-tooltip>
      
      <div 
        class="export-btn" 
        :class="{ disabled: historyEventList.length === 0 || !isReadyAdvancedExport }"
        @click="advancedExport">
        <img src="@/assets/icons/export-events.svg" alt="">
        {{ $t('search_export') }}
      </div>
    </div>
    <div v-if="searchTab === 1 && showAdvancedSelect" class="advanced-search">
      <Select 
        v-for="item in advSelections"
        :key="item.kind"
        class="adv-item"
        :class="{ 'adv-item-wider': item.kind === 'tag' }"
        :title="item.title"
        :multiSelect="item.multiSelect" 
        v-model="advParam[item.kind]" 
        :options="getOptions(item.kind, item.options)"
      />
    </div>
  </div>
</template>

<script>
import Select from './SearchBar/Select.vue'
import SelectInput from './SearchBar/SelectInput.vue'
import RadioSelect from './SearchBar/RadioSelect.vue'
import DateRangeSelect from '@/components/Base/DateRangeSelect.vue'
import { mapState, mapActions, mapMutations } from 'vuex'
import { carType, advancedSelectItems, advancedSelectList } from '@/config/history.js'
import { apiGetBoviaLprEventsReports } from '@/api/index.js'
import { genFormattedDatetime } from '@/utils/lib.js'
import { getLprBasicDateRangeSetting, getLprAdvDateRangeSetting } from '@/config/dateRange.js'

export default {
  name: 'SearchBarLpr', 
  components: {
    TopNav: () => import(`@/components/tools/TopNav.vue`),

    Select,
    SelectInput, 
    RadioSelect,
    DateRangeSelect,
    DeviceRadioTree: () => import('@/components/History/SearchBar/DeviceRadioTree.vue')  
  },
  data() {
    return {
      carType,
      advancedSelectItems,
      advancedSelectList,
      searchTab: 0,
      queryCause: '', // 查詢案由
      licensePlate: '', // 車號
      historyDateTime: [], // 歷史事件時間區間
      deviceDateTime: null, // 設備事件時間區間
      enabledDateStart: null, // 一般查詢可以查詢開始時間
      enabledDateEnd: null, // 一般查詢可以查詢結束時間
      queryType: 'plate', // 查詢類型: plate: 車號, device: 設備
      queryTypeOptions: [
        { label: 'search_plate_number' /*車號*/, value: 'plate' },
        { label: 'search_device' /*設備*/, value: 'device' },
      ],
      selectedDevice: null, // 設備
      basicDateRange: {
        value: [],
        maxRangeDays: 0,
        enabledDateStart: null,
        enabledDateEnd: null,
      },
      
      /** 進階搜尋 **/
      advLicensePlate: '', // 進階搜尋車號
      // advCarType: null, // 車種 null: 全部 , 3: 汽車 , 4: 機車
      advDateTime: [], // 進階搜尋時間區間
      advDateRange: {
        value: [],
        maxRangeDays: 0,
        enabledDateStart: null,
        enabledDateEnd: null,
      },
      advParam: {
        eventCategory: 'all',
        channelId: 'all',
        missionCodes: [1, 2, 3],
        missionStatus: [1, 2, 3], // 全部 
        tag: [],
        executedStatus: [1, 2, 3], // 全部 
        chasedStatus: [3] // 完成
      },
      showAdvancedSelect: true,
    }
  },
  computed: {
    ...mapState(['permissionV2', 'tagList', 'customize']),
    ...mapState('history', [
      'queryCauses',
      'queryNumber',
      'eventPlate', 
      'historyEventList', 
      'historyPageSize', 
      'searchEventLoading',
      'exportList',
      'isReadyBasicExport',
      'isReadyAdvancedExport',
      'getEventsParams',
    ]),
    advSelections() {
      return this.advancedSelectList.filter(item => this.advancedSelectItems[this.advParam.eventCategory].includes(item.kind))
    },
    permissionNavList() {
      const list = []

      if (this.permissionV2.lprEventAccess > 0) {
        list.push({
          navTitle: 'search_tab_history'/*一般查詢*/,
          comp: 0,
        })
      }

      if (this.permissionV2.lprEventAdvancedAccess > 0) {
        list.push({
          navTitle: 'search_tab_advanced_search'/*進階查詢*/,
          comp: 1,
        })
      }
      return list
    },
    isNpa() {
      return this.customize === 'npa'
    }
  },
  watch: {
    searchTab() {
      if (this.searchTab === 0) this.showAdvancedSelect = false
      if (this.searchTab === 1) this.showAdvancedSelect = true
      this.earseSearchParams()
      this.earseAdvSearchParams()
      this.resetHistoryData() // 清空前一tab搜尋的事件
    },
    'permissionV2.lprEventAccess'() {
      if (this.permissionV2.lprEventAccess === 0) this.searchTab = -1
    },
    'advParam.eventCategory'() {
      this.advParam.channelId = 'all' // 全部
      this.advParam.missionCodes = [1, 2, 3] // 全部 
      this.advParam.missionStatus = [1, 2, 3] // 全部 
      this.advParam.tag = []
      this.advParam.executedStatus = [1, 2, 3] // 全部 
      this.advParam.chasedStatus = [3] // 完成
    },
  },
  mounted() {
    // 預設搜尋時間區間，歷史事件預設3個月，進階搜尋預設1個月
    //  2022.10.27 一般查詢：今日 24:00，往前180 天，至多 366 天

    this.initBasicDateRange()
    this.initAdvDateRange()
    this.deviceDateTime = genFormattedDatetime(-30, 'minutes', 'minute') // 設置為當前時間，但是 0 秒鐘、0 毫秒

    // 若eventPlate不為空則以eventPlate帶入車號
    if (this.eventPlate) {
      this.licensePlate = this.eventPlate
    }

    // 接收來自其他元件的事件以及參數
    this.$bus.$on('queryByEvent', (params) => {
      this.queryByEvent(params)
    })
  },
  beforeDestroy() {
    this.$bus.$off('queryByEvent')
  },
  methods: {
    ...mapMutations('history', [
      'updateSearchMode',
      'updateBeforeAfterId',
      'updateGetEventsParams',
      'updateHistoryEventIndex',
      'updateFocusEventIndex',
      'updateHistoryCurrentPage',
      'updateHistoryTotal',
      'updateExportList',
      'updateShowExportInfoModal',
      'updateIsReadyBasicExport',
      'updateIsReadyAdvancedExport',
    ]),
    ...mapActions('history', [
      'searchEvents', 
      'resetHistoryData'
    ]),
    initBasicDateRange() {
      // 車牌一般預設搜尋區間: 預設六個月
      // 車牌一般最大可搜尋區間: 預設最近六個月
      // 車牌一般最多可搜尋的範圍: 預設最近一年
      const { defaultDateRange, maxRangeDays, enabledDateStart, enabledDateEnd } 
        = getLprBasicDateRangeSetting()
      this.basicDateRange.value = defaultDateRange
      this.basicDateRange.maxRangeDays = maxRangeDays
      this.basicDateRange.enabledDateStart = enabledDateStart
      this.basicDateRange.enabledDateEnd = enabledDateEnd
    },
    initAdvDateRange() {
      // 車牌進階預設搜尋區間: 預設一週
      // 車牌進階最大可搜尋區間: 預設最近六個月
      // 車牌進階最多可搜尋的範圍: 預設最近一年
      const { defaultDateRange, maxRangeDays, enabledDateStart, enabledDateEnd } 
        = getLprAdvDateRangeSetting()
      this.advDateRange.value = defaultDateRange
      this.advDateRange.maxRangeDays = maxRangeDays
      this.advDateRange.enabledDateStart = enabledDateStart
      this.advDateRange.enabledDateEnd = enabledDateEnd
    },
    getInputOptions(list) {
      return list.map(item => {
        return {
          label: item,
          value: item
        }
      })
    },
    selectTab(index) {
      // 2023.05.30 進階搜尋: permissionV2.lprEventAdvancedAccess > 0
      // 一般搜尋: permissionV2.lprEventAccess > 0 可以進  
      if (this.permissionV2.lprEventAccess === 0) return
      if (index === 1 && this.permissionV2.lprEventAdvancedAccess === 0) return // 想切換到進階搜尋，但只有一般搜尋的權限
      this.searchTab = index
    },
    showAdvSelect() {
      this.showAdvancedSelect = !this.showAdvancedSelect
    },
    getOptions(kind, options) {
      // 若是tag則須取得vuex tagList
      if (kind === 'tag') {
        const tagOptions = this.tagList.map(tag => {
          return { label: tag.name, value: tag.id, locked: tag.locked }
        })
        return [...options, ...tagOptions]
      } else return options
    },
    handleLicensePlate(vlp) {
      // 將全形逗點轉換成半形逗點
      vlp = vlp.replace(/，/g, ',')

      // 去除半形逗點前後的空格
      vlp = vlp.replace(/\s*,\s*/g, ',')

      // 去除開頭結尾的空格，並將中間的空格轉換成逗點
      vlp = vlp.trim().replace(/\s+/g, ',')

      return vlp
    },
    earseSearchParams() {
      this.selectedDevice = null
      this.queryCause = ''
      this.licensePlate = ''
      this.queryType = 'plate'
      this.initBasicDateRange()
    },
    earseAdvSearchParams() {
      this.selectedDevice = null
      this.advLicensePlate = ''
      // this.advCarType = null
      this.advParam.eventCategory = 'all'
      this.advParam.channelId = 'all' // 全部
      this.advParam.missionCode = [1, 2, 3] // 全部 
      this.advParam.missionStatus = [1, 2, 3] // 全部 
      this.advParam.tag = []
      this.advParam.executedStatus = [1, 2, 3] // 全部 
      this.advParam.chasedStatus = [3] // 完成
      this.initAdvDateRange()
    },
    checkParams() {
      if (this.queryCause.length === 0 || this.queryCause.length > 30) {
        let msg = this.queryCause.length === 0 ? this.$t('search_hint_reason') : this.$t('search_hint_reason_length')
        this.$notify({ title: this.$t('search_hint'), message: msg, type: 'warning' })
        return false
      }

      if (this.queryType === 'plate' && this.licensePlate.length === 0) {
        this.$notify({ title: this.$t('search_hint'), message: this.$t('search_hint_fill_number'), type: 'warning' })
        return false
      }
      
      if (this.queryType === 'device' && !this.selectedDevice.id) {
          this.$notify({ title: this.$t('search_hint'), message: this.$t('search_hint_fill_device'), type: 'warning' })
          return false
      }

      if (this.basicDateRange.value.length === 0 || !this.deviceDateTime) {
        this.$notify({ title: this.$t('search_hint'), message: this.$t('search_hint_fill_time'), type: 'warning' })
        return false
      }
      return true
    },
    async searchHistoryEvents() {
      if (!this.checkParams() || this.searchEventLoading) return
      
      this.updateSearchMode('basic') // 設定搜尋模式為一般搜尋
      this.updateBeforeAfterId(null) // reset beforeAfterId
      let param = {}
      param.purpose = this.queryCause // 查詢案由
      param.limit = this.historyPageSize
      if (this.queryType === 'plate') {
        param.content = this.handleLicensePlate(this.licensePlate)  // 車號
        if (this.basicDateRange.value.length == 2) {  // 時間區間
          param.afterDetectTime = new Date(this.basicDateRange.value[0]).toISOString()
          param.beforeDetectTime = new Date(this.basicDateRange.value[1]).toISOString()
        }
      } else if (this.queryType === 'device') {
        param.user = this.selectedDevice.id // 設備
        param.afterDetectTime = new Date(this.deviceDateTime).toISOString()
        let afterDetectTime = new Date(this.deviceDateTime)
        afterDetectTime.setMinutes(afterDetectTime.getMinutes() + 30)
        param.beforeDetectTime = afterDetectTime.toISOString()
      }

      // 將搜尋參數存至vuex
      this.updateGetEventsParams(param)
      await this.searchEvents({ page: 1 })
      this.updateIsReadyBasicExport(true)

      // 查無資料時跳出提示
      if (this.historyEventList.length === 0) {
        this.$notify({
          title: this.$t('search_hint'),
          message: this.$t('search_hint_nodata'),
          type: 'warning'
        })
      }
    },
    async advancedSearch() {
      if (this.searchEventLoading) return
      this.updateSearchMode('advanced') // 設定搜尋模式為進階搜尋
      this.updateBeforeAfterId(null) // reset beforeAfterId
      this.updateHistoryTotal(0) // 重新查詢時先將總數設為0(畫面顯示讀取中...)
      const param = {}
      param.limit = this.historyPageSize
      // 設備
      if (this.selectedDevice?.id) {
        if (this.selectedDevice.isHasChild) param.group = [this.selectedDevice.groupId]
        else param.user = [this.selectedDevice.id]  
      }

      if (this.advLicensePlate) param.content = this.handleLicensePlate(this.advLicensePlate)  // 車號
      // if (this.advCarType) param.classification = this.advCarType  // 車種

      // 時間區間
      if (this.advDateRange.value.length == 2) {
        param.afterDetectTime = new Date(this.advDateRange.value[0]).toISOString()
        param.beforeDetectTime = new Date(this.advDateRange.value[1]).toISOString()
      }

      // 訂閱
      if (this.advParam.eventCategory === 'subscribed') {
        param.subscribed = 1
      }

      // 任務
      if (this.advParam.eventCategory === 'missioned') {
        // missioned 任務 [1 & 2](未完成) [3] (任務有完成) [1&2&3](全部 )
        param.missioned = this.advParam.missionStatus
        if (this.advParam.missionCodes) // 1:協尋, 2:盤查, 3:逮捕
          param.missionCodes = this.advParam.missionCodes
      }

      // 標記
      if (this.advParam.eventCategory === 'executed') {
        // executed  標記 [1 & 2](未完成) [3] (查緝有完成)[1&2&3](全部 )
        param.executed = this.advParam.executedStatus
      }

      // 車輛標記
      if (this.advParam.tag.length > 0 && this.advParam.tag.length !== this.tagList.length)
        param.tag = this.advParam.tag

      // 圍捕
      if (this.advParam.eventCategory === 'chased') {
        // chased  追車  [2]未完成 [3]完成 (追車不管種類可能是任務或標記)
        param.chased = this.advParam.chasedStatus
      } 

      // 誤判
      if (this.advParam.eventCategory === 'misjudged') {
        param.misjudged = 1
      } else {
        param.misjudged = 0
      }

      // 鏡頭
      if (this.advParam.channelId !== 'all') {
        param.channelId = this.advParam.channelId
      }

      // 將搜尋參數存至vuex
      this.updateGetEventsParams(param)
      await this.searchEvents({ page: 1 })
      this.updateIsReadyAdvancedExport(true)
    },
    async basicExport() {
      if (this.historyEventList.length === 0 || !this.isReadyBasicExport) return
      let res = await apiGetBoviaLprEventsReports()
      this.updateExportList(res.data)
      if (this.exportList.length >= 5) {
        this.updateShowExportInfoModal({ isShow: true, mode: 'warning' })
        return
      }
      // 開啟自訂檔名對話框
      this.updateShowExportInfoModal({ isShow: true, mode: 'filename' })
    },
    async advancedExport() {
      if (this.historyEventList.length === 0 || !this.isReadyAdvancedExport) return
      let res = await apiGetBoviaLprEventsReports()
      this.updateExportList(res.data)
      if (this.exportList.length >= 5) {
        this.updateShowExportInfoModal({ isShow: true, mode: 'warning' })
        return
      }
      // 開啟自訂檔名對話框
      this.updateShowExportInfoModal({ isShow: true, mode: 'filename' })
    },
    async queryByEvent(params) {
      this.queryType = 'device'
      this.selectedDevice = {
        id: params.userId,
        name: params.name,
        groupId: '',
        isHasChild: false,
      }

      // deep copy of this.getEventsParams
      const param = JSON.parse(JSON.stringify(this.getEventsParams))
      delete param.content 
      param.user = this.selectedDevice.id // 設備
      this.queryCause = param.purpose

      // 時間區間
      let afterDetectTime = new Date(params.detectTime)
      afterDetectTime.setMinutes(afterDetectTime.getMinutes() - 15)
      this.deviceDateTime = new Date(afterDetectTime)
      param.afterDetectTime = afterDetectTime.toISOString()
      afterDetectTime.setMinutes(afterDetectTime.getMinutes() + 30)
      param.beforeDetectTime = afterDetectTime.toISOString()

      
      this.updateBeforeAfterId(null) // reset beforeAfterId
      this.updateGetEventsParams(param) // 將搜尋參數存至vuex
      await this.searchEvents({ page: 1 })

      this.updateIsReadyBasicExport(true)
    }
  },
}
</script>

<style>
.el-tooltip {
  font-size: 18px;
  font-weight: 300;
}  
</style>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

::placeholder {
  color: #9D9D9D;
  font-weight: 300;
}

.wrap-search-bar {
  width: 100%;
  height: 100%;
  background: #282942;
  position: relative;
}

.tabs {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  column-gap: 52px;
  background: #151B35;
  border-top: 1px solid #ffffff40;
  padding: 0px 40px;
  .tab {
    font-size: px2rem(24);
    font-weight: 300;
    line-height: 51px;
    color: #ffffff80;
    border-bottom: #151B35 5px solid;
    box-sizing: border-box;
    user-select: none;
    cursor: pointer;
    &.active {
      color: #FFC000;
      border-color: #FFC000;
    }
  }
}

.search-bar {
  width: 100%;
  display: flex;
  color: #ffffff;
  padding: 16px 32px;
}

.action-cause {
  width: 25%;
  margin-right: 40px;
  position: relative;
}

.action-cause::after {
  content: '';
  width: 1px;
  height: 80px;
  background: #ffffff80;
  position: absolute;
  right: -20px;
  top: 2px;
}

.title {
  font-size: px2rem(18);
  font-weight: 300;
  line-height: 28px;
}

.required {
  color: #FFC600;
}

input {
  width: 100%;
  outline: none;
  border: none;
  font-size: 24px;
  font-weight: normal;
  line-height: 32px;
}

.plate-device {
  display: flex;
  margin-right: 20px;
}

.query-type {
  min-width: 84px;
  margin-right: 20px;
}

.plate {
  width: 170px;
}

.query-device {
  width: 320px;
}

.action-cause input, .plate input {
  background: #ffffff;
  // font-family: 'Noto Sans TC', 'Segoe UI', Arial, Helvetica, sans-serif;
  height: px2rem(48);
  padding-left: 15px;
  padding-right: 8px;
  margin-top: 3px;
  border-radius: 8px;
}

.plate input {
  width: 170px;
}

.car-type {
  width: 10%;
  margin-right: 20px;
  z-index: 2;
}

.dt-select {
  width: 25%;
  margin-right: 20px;
}

.search-btn {
  width: 110px;
  height: 48px;
  border: 1px solid #FFC600;
  margin-top: 32px;
  margin-right: 20px;
  border-radius: 9px;
  background-image: url('../../assets/icons/feather-search.svg');
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  justify-content: center;  
  align-items: center;
  cursor: pointer;
}
.search-btn:hover {
  background: #FFC600;
  background-image: url('../../assets/icons/feather-search-w.svg');
  background-repeat: no-repeat;
  background-position: center center;
}

.loading {
  background: #FFC600;
}

.search-btn.loading:hover {
  background-image: url('../../assets/icons/feather-search.svg');
}

.ring {
  width: 30px;
  height: 30px;
  position: relative;
  color: inherit;
  display: inline-block;
  box-sizing: content-box;
  animation: ld-cycle 1s infinite linear;
}

.ring:after {
  content: " ";
  display: block;
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  transform-origin: 0em 0em;
  transform: translateZ(0) scale(0.5);
  backface-visibility: hidden;
  border-radius: 50%;
  border: 7px solid #555555;
  border-left-color: transparent;
}

.tool {
  margin-top: 32px;
  margin-right: 8px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tool:hover {
  background: #4A5C7880;
  border-radius: 9px;
}

@keyframes ld-cycle
{
  0% {
    animation-timing-function: cubic-bezier(0.3333,0.3333,0.6667,0.6667);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.device {
  width: 22vw;
  margin-right: 20px;
}

.device + .plate {
  margin-right: 20px;
}

.export-btn {
  position: absolute;
  bottom: 17px;
  right: 16px;
  height: 48px;
  background: #FFC600;
  padding: 0 16px;
  color: #282942;
  border-radius: 9px;
  font-size: px2rem(18);
  line-height: 48px;
  font-weight: 300;
  user-select: none;
  cursor: pointer;
}

.export-btn img {
  width: 18px;
  height: 20px;
  margin-right: 4px;
  vertical-align: baseline;
}

.export-btn.disabled {
  background: #9D9D9D;
  cursor: default;
  opacity: 0.5;
}

.advanced-search {
  position: absolute;
  left: 12px;
  top: calc(100% + px2rem(8));
  background: #282942b3;
  border-radius: 3px;
  padding: 11px 12px 17px 12px;
  display: flex;
  z-index: 1;
}

.adv-item {
  width: 180px;
}

.adv-item:not(:last-child) {
  margin-right: 20px;
}

.adv-item:last-child {
  margin-left: 20px;
  position: relative;
}

.adv-item:last-child::before {
  content: '';
  position: absolute;
  left: -20px;
  top: 0;
  width: 1px;
  height: 100%;
  background: #ffffff;
}

.adv-item-wider {
  width: 310px;
}

@media screen and (max-width: 1680px) {
  .search-tabs {
    font-size: 22px;
  }
  .title {
    font-size: 20px;  
  } 
  input {
    font-size: 20px;
  }
  .action-cause {
    width: 20%;
  }
  .query-type {
    font-size: 20px;
    margin-right: 10px;
  }

  .query-device {
    width: 240px;
    font-size: 22px;
  }

  .dt-select {
    width: 26%;
  }
  .export-btn {
    font-size: 20px;
  }
}

@media screen and (max-width: 1440px) {
  .search-tabs {
    font-size: 20px;
  }
  .search-bar {
    padding-left: 20px;
  }
  .title {
    font-size: 18px;  
  } 
  .action-cause {
    width: 20%;
  }
  input {
    font-size: 20px;
  }
  .query-type {
    font-size: 20px;
  }

  .plate-device {
    margin-right: 8px;
  }
  .dt-select {
    width: 27%;
  }
  .search-btn {
    width: 6%;
    margin-right: 12px;
  }
  .export-btn {
    font-size: 18px;
    padding: 0 10px;
  }
  
  .action-cause {
    margin-right: 20px;
  }

  .action-cause::after {
    right: -10px;
  }

  .device, .plate, .car-type, .dt-select {
    margin-right: 12px;
  }
}

@media screen and (max-width: 1340px) {
  .export-btn {
    padding: 0px 14px;
    width: 48px;
  }
}

@media screen and (max-width: 840px) {
  .search-tab {
    min-width: 100px;
  }

  .search-bar {
    padding: 16px 12px;
  }

  .action-cause {
    width: 21%;
  } 
  
  .device {
    width: 21%;
  }

  .plate-device {
    width: 20%;
  }

  .dt-select {
    width: 21%;
  }

  .search-btn {
    margin-right: 8px;
  }

  .tool {
    margin-right: 0px;
  }

  .export-btn {
    right: 12px;
    padding: 0px 14px;
    width: 48px;
  }
}

</style>