import i18n from "../i18n/i18n"

export const carType = {
  title: i18n.t('search_car_type')/* 車種 */,
  options: [
    { label: i18n.t('search_all')/* 全部 */, icon: '', value: null },
    { label: i18n.t('search_car')/* 汽車 */, icon: 'car-solid-h.svg', value: 3 },
    { label: i18n.t('search_motocycle')/* 機車 */, icon: 'motorcycle-h.svg', value: 4 },
  ]
}

export const advancedSelectItems = {
  all: [ 'eventCategory', 'channelId' ],
  subscribed: [ 'eventCategory', 'channelId' ],
  missioned: [ 'eventCategory', 'missionCodes', 'missionStatus', 'channelId' ],
  executed: [ 'eventCategory', 'tag', 'executedStatus', 'channelId' ],
  chased: [ 'eventCategory', 'chasedStatus', 'channelId' ],
  misjudged: [ 'eventCategory', 'channelId' ]
}

export const advancedSelectList = [
  { 
    kind: 'eventCategory',
    title: i18n.t('search_event_category')/* 事件類別 */, 
    options: [
      { label: i18n.t('search_all')/* 全部 */, value: 'all' },
      { label: i18n.t('search_subscription')/* 訂閱 */, value: 'subscribed' },
      { label: i18n.t('search_mission')/* 任務 */, value: 'missioned' },
      { label: i18n.t('search_mark')/* 標記 */, value: 'executed' },
      { label: i18n.t('search_round_up')/* 圍捕 */, value: 'chased' },
      { label: i18n.t('search_misjudgment')/* 誤判 */, value: 'misjudged' },
    ],
  },
  {
    kind: 'missionCodes',
    title: i18n.t('search_mission')/* 任務 */,
    options: [
      { label: i18n.t('search_all')/* 全部 */, value: [1, 2, 3], icon: '' },
      { label: i18n.t('search_cofind')/* 協尋 */, value: [1], icon: 'cofind.svg' },
      { label: i18n.t('search_check')/* 盤查 */, value: [2], icon: 'missionCheck.svg' },
      { label: i18n.t('search_arrest')/* 逮捕 */, value: [3], icon: 'arrest.svg' },
    ]
  },
  {
    /* missioned 任務 [1 & 2](未完成) [3] (任務有完成) [1&2&3](全部) */
    kind: 'missionStatus',
    title: i18n.t('search_finished_status')/* 完成狀態 */,
    options: [
      { label: i18n.t('search_all')/* 全部 */, value: [1, 2, 3] },
      { label: i18n.t('search_finish')/* 完成 */, value: [3] },
      { label: i18n.t('search_undone')/* 未完成 */, value: [1, 2] },
    ]
  },
  {
    kind: 'tag',
    title: i18n.t('search_vehicle_marking')/* 車輛標記 */,
    options: [
      // { label: i18n.t('search_all')/* 全部 */, value: 'all' },
    ],
    multiSelect: true,
  },
  {
    /* executed  標記 [1 & 2](未完成) [3] (查緝有完成)[1&2&3](全部)*/
    kind: 'executedStatus',
    title: i18n.t('search_finished_status')/* 完成狀態 */,
    options: [
      { label: i18n.t('search_all')/* 全部 */, value: [1, 2, 3] },
      { label: i18n.t('search_finish')/* 完成 */, value: [3] },
      { label: i18n.t('search_undone')/* 未完成 */, value: [1, 2] },
    ]
  },
  {
    /* chased 追車  [2]未完成 [3]完成 (追車不管種類可能是任務或標記) */
    kind: 'chasedStatus',
    title: i18n.t('search_finished_status')/* 完成狀態 */,
    options: [
      { label: i18n.t('search_undone')/* 未完成 */, value: [2] },
      { label: i18n.t('search_finish')/* 完成 */, value: [3] },
    ]
  },
  {
    kind: 'channelId',
    title: i18n.t('search_channel_id')/* 鏡頭 */,
    options: [
      { label: i18n.t('search_all')/* 全部 */, value: 'all' },
      { label: 'CH1', value: 0 },
      { label: 'CH2', value: 1 },
    ]
  },
  // {
  //   kind: 'executed',
  //   title: i18n.t('search_finished_status')/* 完成狀態 */,
  //   options: [
  //     { label: i18n.t('search_all')/* 全部 */, value: null },
  //     { label: i18n.t('search_finish')/* 完成 */, value: 1 },
  //     { label: i18n.t('search_undone')/* 未完成 */, value: 0 },
  //   ]
  // },
  // {
  //   kind: 'misjudged',
  //   title: i18n.t('search_misjudgment')/* 誤判 */,
  //   options: [
  //     { label: i18n.t('search_all')/* 全部 */, value: null },
  //     { label: i18n.t('search_misjudgment')/* 誤判 */, value: 1 },
  //     { label: i18n.t('search_non_misjudgment')/* 非誤判 */, value: 0 },
  //   ]
  // }
]